define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = [["en", {
    "component": {
      "cards": {
        "articleCard": {
          "reactions": " - {count, plural, =0 {no comments.} =1 {one comment.} other {# comments.}}"
        }
      },
      "footerNav": {
        "by": "Proudly brewed by the ICT committee of C.S.V. Alpha | © ",
        "flux-contact": "Contact us"
      },
      "headerNav": {
        "aboutUs": "About us",
        "becomeMember": "Become a member!",
        "changeLocale": "Verander taal naar Nederlands",
        "sponsoring": "Sponsoring"
      },
      "public": {
        "board": {
          "board-member-stories": {
            "member-1": "LANG-TODO",
            "member-2": "LANG-TODO",
            "member-3": "LANG-TODO",
            "member-4": "LANG-TODO",
            "member-5": "LANG-TODO"
          },
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "intro-title": "LANG-TODO",
          "old-boards": "Oud Besturen",
          "senaat": "Board",
          "slogan": "LANG-TODO"
        },
        "contact": {
          "form": {
            "email": "E-mail",
            "message": "Message",
            "name": "Name",
            "submit": "Send"
          },
          "header": "LANG-TODO",
          "intro": "LANG-TODO"
        },
        "externalRelations": {
          "choose-text": "LANG-TODO",
          "fact-text": "LANG-TODO",
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "ozon-text": "LANG-TODO"
        },
        "flux": {
          "board": {
            "intro": "LANG-TODO",
            "logistics": "Logistics",
            "president": "President",
            "renting": "Renting",
            "secretary": "Secretary",
            "title": "LANG-TODO",
            "treasurer": "Treasurer",
            "vice-president": "Vice President"
          },
          "header": "LANG-TODO",
          "intro": "LANG-TODO"
        },
        "history": {
          "event-1980": "LANG-TODO",
          "event-1981": "LANG-TODO",
          "event-1982": "LANG-TODO",
          "header": "LANG-TODO"
        },
        "identity": {
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "three-vs": {
            "intro": "LANG-TODO",
            "title": "LANG-TODO",
            "verantwoordelijkheid": "LANG-TODO",
            "vorming": "LANG-TODO",
            "vriendschap": "LANG-TODO"
          }
        },
        "index": {
          "aboutAlpha": {
            "intro": "C.S.V. Alpha was founded in 1980 and is the Christian social student association in Enschede, where socializing and Christian formation are combined into one unique student association. Alpha's core principle is \"the message of the Bible as a guideline for life\". This principle is expressed in the aspects of friendship, personal development and responsibility, the essence of the association.",
            "intro-title": "The Christian student association of Enschede",
            "verantwoordelijkheid-text": "Alpha is an association for and by its members. That is why members are encouraged to be committed to the association, but also to their environment. This can be done, for example, through committees, boards or volunteer actions.",
            "verantwoordelijkheid-title": "Responsibility",
            "vorming-text": "At Alpha, we value personal development. This development expresses itself in activities such as association evenings, sing-ins and, of course, the biweekly (bible) study.",
            "vorming-title": "Personal development",
            "vriendschap-text": "Our own bar Flux is the ideal place to build and maintain friendships. Every Thursday we meet here, it is the place where drinks and good conversation go hand in hand.",
            "vriendschap-title": "Friendship"
          },
          "activities": {
            "none": "There are no upcoming open activities.",
            "title": "Calendar"
          },
          "header": {
            "becomeMember": "Join",
            "interested": "Interested",
            "slogan-1": "For who",
            "slogan-2": "believes",
            "slogan-3": "in",
            "slogan-4": "a",
            "slogan-5": "student time!"
          },
          "photos": {
            "more": "More photos",
            "none": "At this moment there are no photoalbums to display.",
            "title": "Photo albums"
          },
          "sponsors": {
            "potential-sponsor": "Also want to sponsor us? Click here"
          },
          "youtube-videos": {
            "title": "C.S.V. Alpha in short"
          }
        },
        "interested": {
          "fill-out-form": "LANG-TODO",
          "header": "LANG-TODO",
          "intro": "LANG-TODO"
        },
        "memberStories": {
          "afke1": "Heyhey! Even before moving to Enschede, I had already heard great stories about Alpha. During the introduction period, I joined a lot of activities and got to know Alpha even better and I decided to become a member. Meanwhile, a year has past and I still enjoy it a lot!",
          "afke2": "What really speaks to me is that everyone is always welcome in the association. There are members from different churches and also those without religion, but everyone hangs out with everyone and everyone has fun with each other. I, myself, am not religious, but I definitely feel me at home within Alpha. The good conversations, the friendships and epic drinks and parties in our pub make sure that Alpha is a great completion of my time as a student in Enschede!",
          "header": "Stories of members",
          "intro": "LANG-TODO",
          "intro-title": "LANG-TODO",
          "laurens1": "Heyhey! I am Laurens. In my first year as student, I joined Alpha. During the Kick-In I came in touch with the association. In the beginning, I doubted whether I wanted to join a student association, but I wanted to make new (Christian) friends in my new surroundings. I never regretted this decision. In my first year, I made many new contacts and enjoyed the activities. From studying, doing sports and drinking some beers with each other, to epic weekends in and out of the country. ",
          "laurens2": "Results after the first year? New friendships, deep and good conversations about religion and I have gained many new experiences in committees. After half a year I started living in an Alpha house. Living together with Christian students is really cool and fulfilling. As a house we also organise epic activities and everyone is always welcome! In short, are you looking for a fun time, friendship and a challenge in your religious life, then Alpha is something for you!",
          "luuk1": "Hey, I am Luuk. When I came to Enschede I did not join a student association immediately, because I didn’t think it would be something for me. After studying for a year a friend of me brought me along to a drink of Alpha. I had such a great evening there, that I seriously started thinking about joining an association. After a few introduction activities, I knew it for sure, I was joining Alpha. ",
          "luuk2": "Up until this day I definitely have not regretted my decision. At Alpha I made friends, participated in committees and started thinking about religion more in-depth. From serious conversations to fun drinks and every now and then a nice party, that is exactly the combination that I like about Alpha. Are you interested? Drop by sometime!",
          "susan": "Hi hi, my name is Susan. When I came to Enschede two years ago I never planned on joining a student association. Nevertheless, some of my housemates dragged me to Alpha and I was sold immediately. Due to the large diversity of the association, there is always something new to experience. Sometimes that is an awesome committee and another time that is a good long conversation in the pub. Also regarding religion there is something for everyone, with very diverse bible study groups, which you can choose, you can decide what you want to study for that year. This way, I believe, that Alpha gives you a lot of space to grow and discover in your religion. I have to thank Alpha regarding a lot of my personal development, and I would make the decision to join again in a split second. Does it seem like fun? Drop by sometime and taste the atmosphere for yourself! "
        },
        "partners": {
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "our-partners": {
            "partner-1": "LANG-TODO",
            "partner-2": "LANG-TODO",
            "partner-3": "LANG-TODO",
            "title": "Our partners"
          }
        },
        "rooms": {
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "intro-title": "LANG-TODO",
          "locatie": "Location"
        }
      },
      "socialMediaButtons": {
        "facebook": "Like us on Facebook",
        "instagram": "Follow us on Instagram",
        "youtube": "Watch us on YouTube"
      },
      "tools": {
        "boardRoomPresence": {
          "absent": "Absent",
          "busy": "Busy",
          "present": "Present"
        }
      },
      "userComment": {
        "anonymous": "Anonymous user"
      }
    },
    "mixin": {
      "menuItems": {
        "activities": "Activities",
        "articles": "Articles",
        "association": "Association",
        "books": "Books",
        "collections": "Collections",
        "forum": "Forum",
        "groups": "Groups",
        "home": "Home",
        "information": "Information",
        "mailAliases": "Mail aliases",
        "mailModerations": "Mail moderations",
        "mandates": "Mandates",
        "members": "Members",
        "nextcloud": "Nextcloud",
        "photoAlbums": "Photo-albums",
        "polls": "Polls",
        "profile": "Profile",
        "recent": "Recent",
        "settings": "Settings",
        "sofia": "S.O.F.I.A",
        "sog": "SOG",
        "sponsorkliks": "Sponsorkliks",
        "staticPages": "Information",
        "users": "Users",
        "vacancies": "Vacancies"
      }
    },
    "model": {
      "activity": {
        "name": {
          "one": "activity",
          "other": "activities"
        }
      },
      "article": {
        "name": {
          "one": "article",
          "other": "articles"
        }
      },
      "articleComment": {
        "name": {
          "one": "comment",
          "other": "comments"
        }
      },
      "debit": {
        "collection": {
          "name": {
            "one": "collection",
            "other": "collections"
          }
        },
        "mandate": {
          "name": {
            "one": "mandate",
            "other": "mandates"
          }
        }
      },
      "forum": {
        "name": {
          "one": "forum",
          "other": "forum"
        }
      },
      "group": {
        "name": {
          "one": "group",
          "other": "groups"
        }
      },
      "mailAlias": {
        "name": {
          "one": "mail alias",
          "other": "mail aliases"
        }
      },
      "mailModeration": {
        "name": {
          "one": "mail moderation",
          "other": "mail moderations"
        }
      },
      "photo": {
        "name": {
          "one": "photo",
          "other": "photos"
        }
      },
      "photoAlbum": {
        "name": {
          "one": "photo album",
          "other": "photo albums"
        }
      },
      "photoComment": {
        "name": {
          "one": "photo comment",
          "other": "photo comments"
        }
      },
      "poll": {
        "name": {
          "one": "poll",
          "other": "polls"
        }
      },
      "staticPage": {
        "name": {
          "one": "infopage",
          "other": "infopages"
        }
      },
      "user": {
        "name": {
          "one": "user",
          "other": "users"
        }
      }
    },
    "tag": {
      "button": {
        "authenticate": "Authenticate",
        "close": "Close",
        "react": "React",
        "readMore": "Read more",
        "signin": "Sign-in",
        "signout": "Sign-out"
      },
      "input": {
        "types": {
          "checkbox": "Checkboxes",
          "number": "Number",
          "radio": "Radio buttons",
          "text": "Short text",
          "textarea": "Long text"
        }
      }
    },
    "template": {
      "articles": {
        "show": {
          "commentHint": "Type your comment here",
          "publicAlert": "This article and the comments below are publicly visible. It is of course very nice to show how active Alpha is, but keep this in mind when posting a comment."
        }
      },
      "login": {
        "authcode": "Authentication code",
        "authcodeHint": "Open the authentication app on your phone and enter the six-figure authentication code. Lost your authentication codes? Then please contact the adminstrators for identification.",
        "forgotPassword": "Forgot your password?",
        "password": "Password",
        "username": "Username"
      },
      "photos": {
        "show": {
          "photographer": "Photo uploaded by"
        }
      },
      "users": {
        "forgotPassword": {
          "email": "E-mail address",
          "recoverPassword": "Recover password"
        }
      }
    }
  }], ["nl", {
    "component": {
      "cards": {
        "articleCard": {
          "reactions": " - {count, plural, =0 {geen reacties.} =1 {1 reactie.} other {# reacties.}}"
        }
      },
      "footerNav": {
        "by": "Met trots gebrouwen door de ICT-commissie der C.S.V. Alpha | © ",
        "flux-contact": "Neem contact op"
      },
      "headerNav": {
        "aboutUs": "Over ons",
        "becomeMember": "Word lid!",
        "changeLocale": "Change language to English",
        "sponsoring": "Sponsoring"
      },
      "public": {
        "board": {
          "board-member-stories": {
            "member-1": "LANG-TODO",
            "member-2": "LANG-TODO",
            "member-3": "LANG-TODO",
            "member-4": "LANG-TODO",
            "member-5": "LANG-TODO"
          },
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "intro-title": "LANG-TODO",
          "old-boards": "Oud Besturen",
          "senaat": "Senaat",
          "slogan": "LANG-TODO"
        },
        "contact": {
          "form": {
            "email": "E-mail",
            "message": "Bericht",
            "name": "Naam",
            "submit": "Versturen"
          },
          "header": "LANG-TODO",
          "intro": "LANG-TODO"
        },
        "externalRelations": {
          "choose-text": "LANG-TODO",
          "fact-text": "LANG-TODO",
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "ozon-text": "LANG-TODO"
        },
        "flux": {
          "board": {
            "intro": "LANG-TODO",
            "logistics": "Logistiek Coördinator",
            "president": "Voorzitter",
            "renting": "Verhuur",
            "secretary": "Secretaris",
            "title": "LANG-TODO",
            "treasurer": "Penningmeester",
            "vice-president": "Vice Voorzitter"
          },
          "header": "LANG-TODO",
          "intro": "LANG-TODO"
        },
        "history": {
          "event-1980": "LANG-TODO",
          "event-1981": "LANG-TODO",
          "event-1982": "LANG-TODO",
          "header": "LANG-TODO"
        },
        "identity": {
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "three-vs": {
            "intro": "LANG-TODO",
            "title": "LANG-TODO",
            "verantwoordelijkheid": "LANG-TODO",
            "vorming": "LANG-TODO",
            "vriendschap": "LANG-TODO"
          }
        },
        "index": {
          "aboutAlpha": {
            "intro": "C.S.V. Alpha is opgericht in 1980 en is dé christelijke gezelligheidsvereniging in Enschede, waar gezelligheid en christelijke vorming gecombineerd worden tot één unieke studentenvereniging. De grondslag van Alpha is \"de boodschap van de Bijbel als richtsnoer voor het leven\". Deze grondslag uit zich in de aspecten vriendschap, vorming en verantwoordelijkheid, de kern van de vereniging.",
            "intro-title": "Dé christelijke gezelligheidsvereniging van Enschede",
            "verantwoordelijkheid-text": "Alpha is een vereniging voor en door de leden. Daarom worden leden aangespoord zich in te zetten voor de vereniging, maar ook voor hun omgeving. Dit kan bijvoorbeeld door middel van commissies, besturen of vrijwilligersacties.",
            "verantwoordelijkheid-title": "Verantwoordelijkheid",
            "vorming-text": "Bij Alpha hebben we persoonlijke ontwikkeling hoog in het vaandel staan. Deze vorming uit zich in activiteiten als verenigingsavonden, sing-ins en natuurlijk de twee wekelijkse kring.",
            "vorming-title": "Vorming",
            "vriendschap-text": "Onze eigen Sociëteit Flux is de ideale plek om vriendschappen op te bouwen en te onderhouden. Elke donderdag ontmoeten we elkaar hier, het is de plek waar een drankje en een goed gesprek hand in hand gaan.",
            "vriendschap-title": "Vriendschap"
          },
          "activities": {
            "none": "Er zijn geen open activiteiten de komende tijd.",
            "title": "Agenda"
          },
          "header": {
            "becomeMember": "Word lid",
            "interested": "Geïnteresseerd",
            "slogan-1": "Voor wie",
            "slogan-2": "gelooft",
            "slogan-3": "in",
            "slogan-4": "een",
            "slogan-5": "studententijd!"
          },
          "photos": {
            "more": "Meer foto's",
            "none": "Er zijn op dit moment geen foto-albums om weer te geven.",
            "title": "Fotoalbums"
          },
          "sponsors": {
            "potential-sponsor": "Ook sponsor worden? Klik hier"
          },
          "youtube-videos": {
            "title": "C.S.V. Alpha in het kort"
          }
        },
        "interested": {
          "fill-out-form": "LANG-TODO",
          "header": "LANG-TODO",
          "intro": "LANG-TODO"
        },
        "memberStories": {
          "afke1": "Heyhey! Al voor dat ik naar Enschede verhuisde hoorde ik goede verhalen over Alpha. Tijdens de introperiode heb ik veel activiteiten meegepakt en zo leerde ik Alpha nog veel beter kennen en heb ik besloten om lid te worden. Ondertussen is er een jaar verstreken en heb ik het nog steeds enorm naar mijn zin!",
          "afke2": "Wat mij enorm aanspreekt in de vereniging is dat iedereen welkom is. Er zijn leden uit allerlei verschillende kerken en ook niet-gelovige mensen, maar iedereen gaat met elkaar om en iedereen heeft het gezellig met iedereen. Zelf ben ik niet gelovig, maar ik voel me zeker helemaal op mijn plek binnen Alpha. De goede gesprekken, de vriendschappen en de epische borrels en feesten in onze sociëteit zorgen ervoor dat Alpha een superinvulling is van mijn studententijd in Enschede!",
          "header": "Verhalen van leden",
          "intro": "LANG-TODO",
          "intro-title": "LANG-TODO",
          "laurens1": "Héhé! Ik ben Laurens. In mijn eerste jaar als student ben ik bij Alpha gegaan. Tijdens de Kick-In ben ik in aanraking gekomen met de vereniging. Ik twijfelde in het begin nog of ik wel bij een studentenvereniging wilde, maar ik wilde graag nieuwe (christelijke) vrienden maken in een nieuwe omgeving. Hiervan heb ik absoluut geen spijt gekregen. In mijn eerste jaar heb ik veel nieuwe contacten gemaakt en genoten van de activiteiten. Van studeren, sporten en een biertje drinken met elkaar tot aan epische weekenden in binnen- en buitenland.",
          "laurens2": "Resultaat na het eerste jaar? Nieuwe vriendschappen, diepe en goede geloofsgesprekken en veel nieuwe ervaring opgedaan in commissies. Na een half jaar kwam ben ik in een Alpha huis gaan wonen. Het samen wonen met christelijke studenten is super gaaf en toereikend. Verder organiseren we als huis epische activiteiten  en is iedereen altijd welkom! Kortom, ben je op zoek naar gezelligheid, vriendschap en uitdaging in je geloofsleven, dan is Alpha echt iets voor jou!",
          "luuk1": "Hey, ik ben Luuk. Toen ik in Enschede kwam ben ik niet direct bij een studentenvereniging gegaan omdat ik niet dacht dat het iets voor mij zou zijn. Na eerst een jaar gestudeerd te hebben nam een vriend van mijn studie me echter een keer mee naar een borrel van Alpha. Daar heb ik toen zo’n gezellige avond gehad dat ik er toch maar serieus over na ging denken om toch bij een vereniging te gaan. Na nog een aantal intro activiteiten wist ik het zeker, ik ga bij Alpha. ",
          "luuk2": "En daar heb ik tot op de dag van vandaag absoluut geen spijt van gehad. Bij Alpha heb ik vrienden gemaakt, in commissies gezeten en ben ik dieper over het geloof gaan nadenken.  Van serieuze gesprekken tot gezellige borrels en zo en dan een mooi feestjes, dat is precies de combinatie die ik  zo tof vind aan Alpha. Ben je geïnteresseerd? Kom dan zeker eens langs!",
          "susan": "Hoihoi, mijn naam is Susan. Toen ik twee jaar geleden naar Enschede kwam was ik nooit van plan om bij een studentenvereniging te gaan. Toch werd ik door huisgenoten een aantal keer meegesleept naar Alpha en ik was al snel verkocht. Door de grootte diversiteit binnen de vereniging is er altijd wel wat nieuws te beleven. Soms is dat een toffe commissie en een andere keer een lang goed gesprek in de kroeg. Ook op geloofsgebied is er voor ieder wat wils, met hele diverse kringen die je zelf mag kiezen kan je elk jaar weer opnieuw bedenken waar je mee bezig wilt. Zo denk ik dat je bij Alpha veel ruimte krijgt om te groeien en te ontdekken in je geloof. Ik heb erg veel van mijn persoonlijke ontwikkeling te danken aan Alpha en zou de keuze om erbij te gaan zo weer maken. Lijkt het jou ook leuk? Kom dan vooral een keer kijken en proef de sfeer zelf!"
        },
        "partners": {
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "our-partners": {
            "partner-1": "LANG-TODO",
            "partner-2": "LANG-TODO",
            "partner-3": "LANG-TODO",
            "title": "Onze partners"
          }
        },
        "rooms": {
          "header": "LANG-TODO",
          "intro": "LANG-TODO",
          "intro-title": "LANG-TODO",
          "locatie": "Locatie"
        }
      },
      "socialMediaButtons": {
        "facebook": "Like ons op Facebook",
        "instagram": "Volg ons op Instagram",
        "youtube": "Bekijk ons op YouTube"
      },
      "tools": {
        "boardRoomPresence": {
          "absent": "Afwezig",
          "busy": "Bezig",
          "present": "Aanwezig"
        }
      },
      "userComment": {
        "anonymous": "Anonieme gebruiker"
      }
    },
    "mixin": {
      "menuItems": {
        "activities": "Activiteiten",
        "articles": "Artikelen",
        "association": "Vereniging",
        "books": "Boeken",
        "collections": "Incasso's",
        "forum": "Forum",
        "groups": "Groepen",
        "home": "Home",
        "information": "Informatie",
        "mailAliases": "Mail aliassen",
        "mailModerations": "Mail moderaties",
        "mandates": "Mandaten",
        "members": "Leden",
        "nextcloud": "Nextcloud",
        "photoAlbums": "Fotoalbums",
        "polls": "Polls",
        "profile": "Profiel",
        "recent": "Actueel",
        "settings": "Instellingen",
        "sofia": "Streepsysteem",
        "sog": "SOG",
        "sponsorkliks": "Sponsorkliks",
        "staticPages": "Informatie",
        "users": "Gebruikers",
        "vacancies": "Vacatures"
      }
    },
    "model": {
      "activity": {
        "name": {
          "one": "activiteit",
          "other": "activiteiten"
        }
      },
      "article": {
        "name": {
          "one": "artikel",
          "other": "artikelen"
        }
      },
      "articleComment": {
        "name": {
          "one": "reactie",
          "other": "reacties"
        }
      },
      "debit": {
        "collection": {
          "name": {
            "one": "incasso",
            "other": "incasso's"
          }
        },
        "mandate": {
          "name": {
            "one": "mandaat",
            "other": "mandaten"
          }
        }
      },
      "forum": {
        "name": {
          "one": "forum",
          "other": "fora"
        }
      },
      "group": {
        "name": {
          "one": "groep",
          "other": "groepen"
        }
      },
      "mailAlias": {
        "name": {
          "one": "mail alias",
          "other": "mail aliassen"
        }
      },
      "mailModeration": {
        "name": {
          "one": "mail moderatie",
          "other": "mail moderaties"
        }
      },
      "photo": {
        "name": {
          "one": "foto",
          "other": "foto's"
        }
      },
      "photoAlbum": {
        "name": {
          "one": "fotoalbum",
          "other": "fotoalbums"
        }
      },
      "photoComment": {
        "name": {
          "one": "foto reactie",
          "other": "foto reacties"
        }
      },
      "poll": {
        "name": {
          "one": "poll",
          "other": "polls"
        }
      },
      "staticPage": {
        "name": {
          "one": "infopagina",
          "other": "infopagina's"
        }
      },
      "user": {
        "name": {
          "one": "gebruiker",
          "other": "gebruikers"
        }
      }
    },
    "tag": {
      "button": {
        "authenticate": "Authenticeren",
        "close": "Sluiten",
        "react": "Reageren",
        "readMore": "Meer lezen",
        "signin": "Inloggen",
        "signout": "Uitloggen"
      },
      "input": {
        "types": {
          "checkbox": "Meerdere opties mogelijk",
          "number": "Nummer",
          "radio": "Één optie mogelijk",
          "text": "Korte tekst",
          "textarea": "Lange tekst"
        }
      }
    },
    "template": {
      "articles": {
        "show": {
          "commentHint": "Typ hier je reactie",
          "publicAlert": "Dit artikel is ook te zien voor niet-leden, dat geldt ook voor de reacties die er geplaatst worden. Het is natuurlijk heel fijn om te laten zien hoe actief Alpha is, maar houd er wel rekening mee als je wat plaatst."
        }
      },
      "login": {
        "authcode": "Authenticatiecode",
        "authcodeHint": "Open de authenticatie app op je telefoon en geef hier de zescijferige authenticatiecode op. Authenticatie codes kwijtgeraakt? Neem dan contact op met de beheerders van de site voor identificatie.",
        "forgotPassword": "Wachtwoord vergeten?",
        "password": "Wachtwoord",
        "username": "Gebruikersnaam"
      },
      "photos": {
        "show": {
          "photographer": "Foto geüpload door"
        }
      },
      "users": {
        "forgotPassword": {
          "email": "E-mailadres",
          "recoverPassword": "Wachtwoord herstellen"
        }
      }
    }
  }]];
  _exports.default = _default;
});